import {envConfig} from "@/utils/env";

const {baseUrl, rtc_appid, webIMappKey} = envConfig;
// console.log(baseUrl, rtc_appid, webIMappKey);
const _ = baseUrl + '/api/v1';
const _v2 = baseUrl + '/api/v2';
const _mock = '/api/v1';

const url = {
    auth: baseUrl + '/auth',
    matic_market: baseUrl + '/matic_market',
    quotes: baseUrl + '/quotes',
    trade_price: baseUrl + '/trade_price',
    balanceOf: baseUrl + '/balanceOf',
    checkcode: _ + '/checkcode',
    bindtiktok: _ + '/bindtiktok',
    searchaccount: _ + '1/searchaccount',
    getcodestate: _ + '/getcodestate',
    searchuser: _ + '/searchuser',
    userinfo: _ + '/userinfo',
    key_buy: _ + '/key_buy',
    key_sell: _ + '/key_sell',
    getkeyinfo: _ + '/getkeyinfo',
    key_history_self: _ + '/key_history_self',
    key_history_friends: _ + '/key_history_friends',
    key_history_global: _ + '/key_history_global',
    earninfo: _ + '/earninfo',
    pri_key: _ + '/pri_key',
    withdraw: _ + '/withdraw',
    getRtcTokenUri: _ + '/agora_gettoken',
    agora_setchantype: _ + '/agora_setchantype',
    rank_toplist: _ + '/rank_toplist',
    agora_host_livestate: _ + '/agora_host_livestate',
    agora_channel_info: _ + '/agora_channel_info',
    ease_token: _ + '/ease_token',
    ease_join_group: _ + '/ease_join_group',
    usersimpleinfo: _ + '/usersimpleinfo',
    ease_heartbeat: _ + '/ease_heartbeat',
    tweet_challenge: _ + '/tweet_challenge',
    tweet_authorize: _ + '/tweet_authorize',
    key_pretrade: _ + '/key_pretrade',
    check_buyfirstkey: _ + '/check_buyfirstkey',
    config: baseUrl + '/config',
    relogin: _ + '/relogin',
    tweetcode: _ + '/tweetcode',
    tweetcodeState: _ + '/tweetcodeState',
    twitter_oauthtoken: _ + '/twitter_oauthtoken',
    trivia_run_data: _v2 + '/trivia_info',
    trivia_answer_choice: _ + '/trivia_choice',
    trivia_start: _ + '/trivia_start',
    trivia_question_next: _ + '/trivia_next_q',
    trivia_answer_pub: _ + '/trivia_pub_a',
    trivia_question_pub: _ + '/trivia_pub_q',
    trivia_rank_pub: _ + '/trivia_pub_r',
    trivia_stop: _ + '/trivia_stop',
    trivia_cond: _ + '/trivia_cond',
    trivia_revival: _ + '/trivia_revival',
    home_showlist: baseUrl + '/home_showlist',
    twitter_update: _ + '/twitter_update',
    live_announce_replace: _ + '/live_announce_replace',
    live_announce_del: _ + '/live_announce_del',
    live_announce_query: _ + '/live_announce_query',
    agora_channel_joinstate: _ + '/agora_channel_joinstate',
    rank_unitdata: _ + '/rank_unitdata',

    batch_price: _ + '/batch_query_price',
    sweep_search: _ + '/sweep_search',

    use_platform: _ + '/use_platform',
    wish_create: _ + '/wish_create',
    referralcode_bind: _ + '/referralcode_bind',
    referralcode_earndata: _ + '/referralcode_earndata',
    referralcode_inviteuser: _ + '/referralcode_inviteuser',
    referralcode_regen: _ + '/referralcode_regen',
    activity_info: _ + '/activity_info',
    live_title_replace: _ + '/live_title_replace',
    wish_query: _ + '/wish_query',

    hire_list: _v2 + '/friendtrade_hirelist',
    portfolio: _ + '/portfolio',
    take_coin: _ + '/friendtrade_takecoin',
    dispatch_emplyees: _ + '/friendtrade_dispatch_emplyees',
    work_list: _ + '/friendtrade_work_cfglist',
    unlock_work: _ + '/friendtrade_unlockwork',
    key_mulpretrade: _ + '/key_mulpretrade',
    dispatch_detail: _ + '/friendtrade_dispatch_detail',
    history_friends: _ + '/history_friends',
    global_chat_userid: _ + '/global_chat_userid',
    friendtrade_rank_selfdata: _v2 + '/friendtrade_rank_selfdata',
    friendtrade_rank_claim: _v2 + '/friendtrade_rank_claim',
    friendtrade_loot_nftrank: _v2 + '/friendtrade_loot_nftrank',
    friendtrade_loot_employeelist: _v2 + '/friendtrade_loot_employeelist',
    friendtrade_loot_info: _ + '/friendtrade_loot_info',
    friendtrade_loot_crack: _ + '/friendtrade_loot_crack',
    friendtrade_loot_randomsearch: _v2 + '/friendtrade_loot_randomsearch',
    friendtrade_takeworkcoin: _ + '/friendtrade_takeworkcoin',
    friendtrade_workinfo: _ + '/friendtrade_workinfo',
    friendtrade_boost_claimcoin: _ + '/friendtrade_boost_claimcoin',
    global_production_info: _ + '/global_production_info',
    twitter_link_create: _ + '/twitter_link_create',
    friendtrade_chain_unlockwork: _ + '/friendtrade_chain_unlockwork',
    friendtrade_chain_lootcrack: _ + '/friendtrade_chain_lootcrack',
    friendtrade_chain_orderresult: _ + '/friendtrade_chain_orderresult',
    dispatch_emplyees_mutil: _ + '/friendtrade_dispatch_batch_emplyees',
    dispatch_detail_mutil: _v2 + '/friendtrade_dispatch_batch_detail',
    friendtrade_takeworkcoin_all: _ + '/friendtrade_takeworkcoin_all',
    friendtrade_takecoininfo: _ + '/friendtrade_takecoininfo',
    friendtrade_virtual_lfg: _ + '/friendtrade_virtual_lfg',
    friendtrade_chain_stake: _ + '/friendtrade_chain_stake',
    friendtrade_locklfg_info: _ + '/friendtrade_locklfg_info',
    friendtrade_mintunlocklfg: _ + '/friendtrade_mintunlocklfg',
    stake_yield_claimed: _v2 + '/stake_yield_claimed',
    redpacket_list: _ + '/redpacket_list',
    redpacket_detail: _ + '/redpacket_detail',
    redpacket_expire: _ + '/redpacket_expire',
    set_nftavatar: _ + '/set_nftavatar',
    nft_ownList: _ + '/nft_ownList',
    nft_auction_batchcfg: _ + '/nft_auction_batchcfg',
    nft_auction_batchinfo: _ + '/nft_auction_batchinfo',
    nft_self_bidlist: _ + '/nft_self_bidlist',
    nft_self_claimlist: _ + '/nft_self_claimlist',
    nft_self_history: _ + '/nft_self_history',
    nft_detail: _ + '/nft_detail',
    nft_auction: _v2 + '/nft_auction',
    nft_bid_status: _ + '/nft_bid_status',
    nft_bid_history: _ + '/nft_bid_history',
    batch_users_profile: _v2 + '/batch_users_profile',
    nft_batch_bidresult: _ + '/nft_batch_bidresult',
    uppoints_event: _v2 + '/uppoints_event',
    referral_stake_get_reward: _ + '/referralcode_stake_getreward',
    referralcode_stakelfg: _ + '/referralcode_stakelfg',
    nft_auction_refund: _v2 + '/nft_auction_refund',
    citywar_citydetail: _v2 + '/citywar_citydetail',
    citywar_infolist: _v2 + '/citywar_infolist',
    citywar_unlock: _ + '/citywar_unlock',
    citywar_cityranklist: _v2 + '/citywar_cityranklist',
    citywar_selfinfo: _ + '/citywar_selfinfo',
    citywar_move: _ + '/citywar_move',

    // new UI add API

    friendtrade_worklist: _v2 + '/friendtrade_worklist',
    friendtrade_workdetail: _v2 + '/friendtrade_workdetail',
    friendtrade_candispatchlist: _v2 + '/friendtrade_candispatchlist',
    friendtrade_takeworkprofit: _ + '/friendtrade_takeworkprofit',
    friendtrade_dispatch_detail: _ + '/friendtrade_dispatch_detail',
    friendtrade_loot_matchlist: _v2 + '/friendtrade_loot_matchlist',
    friendtrade_loot_lastevent: _v2 + '/friendtrade_loot_lastevent',
    task_getreward: _ + '/task_getreward',
    nft_protectlfg_mint: _ + '/nft_protectlfg_mint',

    // X farm

    wheelinfo:  _ + '/farm_wheelinfo',
    doWheel:  _ + '/farm_dowheel',
    farm_topic_ranklist:  _v2 + '/farm_topic_ranklist',
    farm_topic_tweetlist:  _v2 + '/farm_topic_tweetlist',
    farm_user_ranklist:  _v2 + '/farm_user_ranklist',
    farm_self_tweetlist:  _v2 + '/farm_self_tweetlist',
    farm_report: _v2 + '/farm_report',
    farm_buy_dowheel: _ + '/farm_buy_dowheel',
    farm_task_count: _v2 + '/farm_task_count',
    boost_event: _v2 + '/boost_event',

    // memeClub
    meme_create:  _ + '/meme_create',
    meme_clublist:  _v2 + '/meme_clublist',
    meme_setfavor:  _v2 + '/meme_setfavor',
    meme_mintcoin: _ + '/meme_mintcoin',
    meme_topprogress:  _v2 + '/meme_topprogress',
    meme_searchclub:  _v2 + '/meme_searchclub',
    meme_clubinfo:  _v2 + '/meme_clubinfo',
    meme_activity:  _v2 + '/meme_activity',
    meme_tradeInfo: _v2 + '/meme_tradeinfo',
    meme_holderlist:  _v2 + '/meme_holderlist',
    meme_userTabList:  _v2 + '/meme_usertablist',
    meme_tgeclublist:  _v2 + '/meme_tgeclublist',
    meme_selfholdlist: _ + '/meme_selfholdlist',
    meme_club_dnt_list: _ + '/meme_club_dnt_list',
    meme_tgecanopenlist: _v2 + '/meme_tgecanopenlist',
    meme_kingtgelist: _v2 + '/meme_kingtgelist',
    meme_kingtgewinnerlist: _v2 + '/meme_kingtgewinnerlist',
    meme_tge_nftranklist: _v2 + '/meme_tge_nftranklist',
    meme_tge_nftinfo: _v2 + '/meme_tge_nftinfo',
    meme_tge_nftcount: _v2 + '/meme_tge_nftcount',
    meme_tradevol_info: _v2 + '/meme_tradevol_info',
    referralcode_memetrade_reflist: _v2 + '/referralcode_memetrade_reflist',
    friendtrade_loot_tarinfo: _v2 + '/friendtrade_loot_tarinfo',
    meme_tge_noticelist: _ +'/meme_tge_noticelist',
    meme_tge_noticesetread: _ + '/meme_tge_noticesetread',
    meme_genimage: _ + '/meme_genimage',
    upload_image: _ + '/upload_image',
    meme_genattrs: _ + '/meme_genattrs',
    meme_setanonymous: _ + '/meme_setanonymous',
    meme_ownclublist: _v2 + '/meme_ownclublist',
    meme_tradelog: _v2 + '/meme_tradelog',

    // momentClub
    moment_buycard: _ + '/moment_buycard',
    moment_sellcard: _ +'/moment_sellcard',

    moment_create:  _ + '/moment_create',
    moment_clublist:  _v2 + '/moment_clublist',
    moment_setfavor:  _v2 + '/moment_setfavor',
    moment_setlike:  _ + '/moment_setlike',
    moment_mintcoin: _ + '/moment_mintcoin',
    moment_topprogress:  _v2 + '/moment_topprogress',
    moment_searchclub:  _v2 + '/moment_searchclub',
    moment_clubinfo:  _v2 + '/moment_clubinfo',
    moment_activity:  _v2 + '/moment_activity',
    moment_tradeInfo: _v2 + '/moment_tradeinfo',
    moment_holderlist:  _v2 + '/moment_holderlist',
    moment_userTabList:  _v2 + '/moment_usertablist',
    moment_tgeclublist:  _v2 + '/moment_tgeclublist',
    moment_selfholdlist: _ + '/moment_selfholdlist',
    moment_club_dnt_list: _ + '/moment_club_dnt_list',
    moment_tgecanopenlist: _v2 + '/moment_tgecanopenlist',
    moment_kingtgelist: _v2 + '/moment_kingtgelist',
    moment_kingtgewinnerlist: _v2 + '/moment_kingtgewinnerlist',
    moment_tge_nftranklist: _v2 + '/moment_tge_nftranklist',
    moment_tge_nftinfo: _v2 + '/moment_tge_nftinfo',
    moment_tge_nftcount: _v2 + '/moment_tge_nftcount',
    moment_tradevol_info: _v2 + '/moment_tradevol_info',
    moment_tge_noticelist: _ +'/moment_tge_noticelist',
    moment_tge_noticesetread: _ + '/moment_tge_noticesetread',
    moment_genimage: _ + '/moment_genimage',
    moment_genattrs: _ + '/moment_genattrs',
    moment_setanonymous: _ + '/moment_setanonymous',
    moment_ownclublist: _v2 + '/moment_ownclublist',
    moment_pre_openpack: _v2 + '/moment_pre_openpack',
    moment_tradelog: _v2 + '/moment_tradelog',
    video_play_url: _ + '/video_play_url',

    moment_video_upload_token : _ + '/video_upload_token',
    moment_video_upload_token_refresh : _ + '/video_upload_token_refresh',
    moment_video_check : _ + '/video_check',
    moment_video_play_token : _ + '/video_play_token',
    moment_clubcardlist : _v2 + '/moment_clubcardlist',
    moment_owncardlist : _v2 + '/moment_owncardlist',
    moment_mintresult: _ + '/moment_mintresult',
    moment_buyresult : _ + '/moment_buyresult',
    moment_twitter_share: _ + '/moment_twitter_share',
    referralcode_momenttrade_reflist: _v2 + '/referralcode_momenttrade_reflist',
    moment_prebuyprice : _v2 + '/moment_prebuyprice',

    moment_upchatlog: _v2 + '/moment_upchatlog',
}
export {
    url,
    rtc_appid,
    webIMappKey,
    baseUrl,
    _v2,
    _,
}
