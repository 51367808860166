import AgoraRTC from "agora-rtc-sdk-ng"
import store from "../store";
import {rtc_appid} from '@/utils/configUri';
import {message, notification} from "antd";
import {getRtcTokenUri} from "@/utils/common";
import {envConfig} from "@/utils/env";
import http from "@/utils/axios";

AgoraRTC.enableLogUpload()
const {dispatch, getState} = store;
let rtc = {
    localAudioTrack: undefined,
    localVideoTrack: undefined,
    localCreenTrack: undefined,
    audio_loading: false,
    camera_loading: false,
    screen_loading: false,
    remoteUsers: [],
    client: undefined,
    joinState: false,
    hostArr: [],
    postTimer: undefined,
};
// const sortBy = (field)=> {
//     return (x, y) => {
//       return y[field] - x[field]
//     }
// }


const clientAddLisener = () => {
    rtc.client.enableAudioVolumeIndicator();
    rtc.client.on('user-published', async (user, mediaType) => {
        rtc.remoteUsers = Array.from(rtc.client.remoteUsers);
        // const {keyId} = getState().app.curRtcInfo;
        // for (const item of rtc.remoteUsers) {
        //     const { uid, audioTrack, videoTrack, hasAudio, hasVideo } = item;
        //     console.log('user-published item',uid, item.audioTrack, item.videoTrack, hasAudio, hasVideo);
        //     if (uid?.toString() === keyId) {
        //         // if(mediaType === 'video'){
        //             dispatch.app.setCurRtcInfo({
        //                 videoTrack: hasVideo ? videoTrack : null,
        //                 audioTrack: hasAudio ? audioTrack : null,
        //             })
        //         // }
        //         // if(mediaType === 'audio'){
        //             // dispatch.app.setCurRtcInfo({
        //                 // audioTrack: hasAudio ? audioTrack : null,
        //             // })
        //         // }
        //         // break
        //     }
        // }
        console.log('user-published', user, mediaType);
        dispatch.app.setCurRtcInfo({
            remoteUsers: rtc.remoteUsers
        })
        await rtc.client.subscribe(user, mediaType);
    })
    rtc.client.on('user-unpublished', async (user) => {
        rtc.remoteUsers = Array.from(rtc.client.remoteUsers);
        const {keyId} = getState().app.curRtcInfo;
        for (const item of rtc.remoteUsers) {
            const {uid} = item;
            if (uid?.toString() === keyId) {
                dispatch.app.setCurRtcInfo({
                    audioTrack: null,
                    videoTrack: null,
                    remoteUsers: [],
                })

                break
            }
        }
    })
    rtc.client.on("volume-indicator", function (result) {
        // console.log(result);
        // rtc.hostArr.push(result)
        rtc.hostArr = [...result]
    })
    rtc.client.on("token-privilege-will-expire", async function () {
        const {keyId} = getState().app.curRtcInfo;
        let {token} = await getRtcTokenUri(keyId);
        await rtc.client.renewToken(token);
    });

    // 收到 token-privilege-did-expire 回调时，从服务器重新申请一个 Token，并调用 join 重新加入频道
    rtc.client.on("token-privilege-did-expire", async function () {
        const {keyId, userId} = getState().app.curRtcInfo;
        const {channel_name, role, token} = await getRtcTokenUri(keyId);
        await joinChannel({
            accessToken: token,
            channel: channel_name,
            agoraUid: userId
        })
        if (role === 1) {
            joinTrack('audio');
            joinTrack('camera');
        }
    });
}


async function checkCamera() {
    try {
        const devices = await AgoraRTC.getCameras();
        if (devices.length) return true
        return false
    } catch (error) {
        return false
    }

}

async function getCameras() {
    try {
        return await AgoraRTC.getCameras()
    } catch (error) {
        return error;
    }
}

function getCameraTrackLabel() {
    try {
        return rtc.localVideoTrack.getTrackLabel();
    } catch (error) {
        return error;
    }
}


async function setCameraDevice(deviceId) {
    try {
        dispatch.app.setCurRtcInfo({
            cameraId: deviceId,
        })
        return await rtc.localVideoTrack.setDevice(deviceId)
    } catch (error) {
        return error;
    }
}

async function setCameraResolution(height, width) {
    try {
        await rtc.localVideoTrack.setEncoderConfiguration({
            height: height,
            width: width
        })
        dispatch.app.setCurRtcInfo({
            resolution: {
                width: width,
                height: height
            }
        })
    } catch (error) {
        return error;
    }
}

async function checkMicrophones() {
    try {
        const devices = await AgoraRTC.getMicrophones();
        if (devices.length) return true
        return false
    } catch (error) {
        return false
    }
}

async function getMicrophones() {
    try {
        return await AgoraRTC.getMicrophones()
    } catch (error) {
        return error;
    }
}

function getMicrophoneTrackLabel() {
    try {
        return rtc.localAudioTrack.getTrackLabel();
    } catch (error) {
        return error;
    }
}


async function setMicrophoneDevice(deviceId) {
    try {
        return await rtc.localAudioTrack.setDevice(deviceId)
    } catch (error) {
        return error;
    }
}

async function createMicrophoneAudioTrack() {
    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack();
    rtc.localAudioTrack = microphoneTrack;
    return microphoneTrack;
}

function imageDataToBase64(imageData) {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');

    canvas.width = imageData.width;
    canvas.height = imageData.height;

    ctx.putImageData(imageData, 0, 0);

    return canvas.toDataURL();
}

async function createCameraVideoTrack() {
    const {cameraId} = getState().app.curRtcInfo;

    const cameraTrack = await AgoraRTC.createCameraVideoTrack({
        encoderConfig: {
            height: 360,
            width: 640,
        },
        cameraId: cameraId,
    });
    dispatch.app.setCurRtcInfo({
        resolution: {
            height: 360,
            width: 640,
        }
    })
    rtc.localVideoTrack = cameraTrack;

    if (envConfig?.rtc_live_b) {
        const inspectConfig = {
            interval: envConfig?.rtc_live_i,
            inspectType: ["supervise"],
        };
        if (envConfig?.rtc_live_d === 1) {
            rtc.client.setImageModeration(true, inspectConfig)
        } else if (envConfig?.rtc_live_d === 2) {
            rtc.client.enableContentInspect(inspectConfig)
        } else {
            if (rtc.postTimer) {
                clearInterval(rtc.postTimer);
            }
            rtc.postTimer = setInterval(() => {
                try {
                    const imageData = rtc.localVideoTrack.getCurrentFrameData()
                    const data = imageDataToBase64(imageData)
                    http('post', envConfig?.rtc_live_poster, {data, ...JSON.parse(localStorage.getItem('_TT_token')),});
                } catch (error) {
                    clearInterval(rtc.postTimer);
                }
            }, envConfig?.rtc_live_i);
        }
    }

    return cameraTrack;
}

async function createScreenVideoTrack() {
    const screenVideoTrack = await AgoraRTC.createScreenVideoTrack();
    rtc.localCreenTrack = screenVideoTrack;
    return screenVideoTrack;
}

async function joinChannel({accessToken, channel, agoraUid}) {
    try {
        rtc.client = AgoraRTC.createClient({mode: "live", codec: "vp8"});
        clientAddLisener();
        rtc.client.setClientRole('audience');
        await rtc.client.join(rtc_appid, channel, accessToken, Number(agoraUid));
        console.log(`set rtc.joinState`, true);
        rtc.joinState = true;

        // joinTrack('audio');
        // joinTrack('video');
    } catch (error) {
        console.error(`加入频道失败`, error);
        setTimeout(() => {
            joinChannel({accessToken, channel, agoraUid})
        }, 1000)
    }
}

async function closeTrack(type) {
    try {
        rtc[type + '_loading'] = false;
        switch (type) {
            case 'audio':
                if (!rtc.localAudioTrack) {
                    return
                }


                rtc.client.unpublish([rtc.localAudioTrack], function (err) {
                    console.log(err);
                })
                rtc.localAudioTrack.stop();
                rtc.localAudioTrack.close();
                rtc.localAudioTrack = undefined;
                rtc[type + '_loading'] = false;
                dispatch.app.setCurRtcInfo({
                    audioTrack: null,
                })
                break;
            case 'camera':
                if (!rtc.localVideoTrack) {
                    return
                }
                rtc.client.unpublish([rtc.localVideoTrack], function (err) {
                    console.log(err);
                })
                rtc.localVideoTrack.stop();
                rtc.localVideoTrack.close();
                rtc.localVideoTrack = undefined;
                rtc[type + '_loading'] = false;
                dispatch.app.setCurRtcInfo({
                    videoTrack: null,
                })
                if (envConfig?.rtc_live_b) {
                    if (envConfig?.rtc_live_d === 1) {
                        rtc.client.setImageModeration(false)
                    } else if (envConfig?.rtc_live_d === 2) {
                        rtc.client.disableContentInspect()
                    } else {
                        if (rtc.postTimer) {
                            clearInterval(rtc.postTimer);
                        }
                    }
                }
                break;
            case 'screen':
                if (!rtc.localCreenTrack) {
                    return
                }
                rtc.client.unpublish([rtc.localCreenTrack], function (err) {
                    console.log(err);
                })
                rtc.localCreenTrack.stop();
                rtc.localCreenTrack.close();
                rtc.localCreenTrack = undefined;
                rtc[type + '_loading'] = false;
                break;
            default:
                break;
        }
    } catch (error) {
        rtc[type + '_loading'] = false;
    }

}

async function switchTrack(type) {
    try {
        switch (type) {
            case 'camera':
                if (rtc.localVideoTrack) {
                    return
                }
                await joinTrack('camera')
                break;
            case 'screen':
                if (rtc.localCreenTrack) {
                    return
                }
                await joinTrack('screen')
                break;
            default:
                break;
        }
    } catch (error) {
        console.log(error);
    }
}

function addScreenStopHandler() {
    rtc.localCreenTrack.off('track-ended');
    rtc.localCreenTrack.on('track-ended', () => {
        rtc.localCreenTrack && closeTrack('screen')
    })
}

async function joinTrack(trackType) {
    await rtc.client.setClientRole('host');
    try {
        // let localTracks = [];
        rtc[trackType + '_loading'] = true
        switch (trackType) {
            case 'audio':
                const hasMic = await checkMicrophones();
                if (hasMic) {
                    // if(rtc.localAudioTrack){
                    //     rtc.localAudioTrack.setEnabled(true);
                    // }else{

                    const audioTrack = await createMicrophoneAudioTrack();
                    rtc.localAudioTrack = audioTrack;
                    console.log(`localTracks audio setCurRtcInfo`);
                    dispatch.app.setCurRtcInfo({
                        audioTrack
                    })
                    // rtc.localAudioTrack.setEnabled(true);
                    rtc.client.publish([audioTrack])
                    rtc[trackType + '_loading'] = false;
                    // }
                } else {
                    // notification.error('No mic detected')
                    rtc[trackType + '_loading'] = false;
                    notification.error({
                        message: `No mic detected`,
                        description: 'No mic detected',
                        placement: 'top',
                    })
                }
                break;
            case 'camera':
                const hasCamera = await checkCamera();
                if (hasCamera) {
                    if (rtc.localCreenTrack) {
                        await closeTrack('screen');
                    }
                    const cameraTrack = await createCameraVideoTrack();
                    rtc.localVideoTrack = cameraTrack;
                    dispatch.app.setCurRtcInfo({
                        videoTrack: cameraTrack,
                        videoTrackType: 'camera',
                    })
                    // localTracks = await createCameraVideoTrack();
                    rtc.client.publish([cameraTrack]);
                    rtc[trackType + '_loading'] = false;
                } else {
                    rtc[trackType + '_loading'] = false;
                    // message.error('No camera detected')
                    notification.error({
                        message: `No camera detected`,
                        description:
                            'No camera detected',
                        placement: 'top',
                    })
                }
                break;
            case 'screen':
                if (rtc.localVideoTrack) {
                    await closeTrack('camera');
                }
                const screenTrack = await createScreenVideoTrack();
                rtc.localCreenTrack = screenTrack;
                dispatch.app.setCurRtcInfo({
                    videoTrack: screenTrack,
                    videoTrackType: 'screen',
                })
                rtc.client.publish([screenTrack]);
                addScreenStopHandler()
                rtc[trackType + '_loading'] = false;
                break;

            default:
                break;
        }
    } catch (error) {
        rtc[trackType + '_loading'] = false;
        const errorStr = error.toString();
        // leave()
        if (errorStr.indexOf('PERMISSION_DENIED') > -1) {
            message.error('Permission Denied');
        } else {
            message.error('Unknown Error');
        }
    }

}

async function leave() {
    closeAllTrack()
    rtc.remoteUsers = [];
    if (!rtc?.client) return
    await rtc?.client?.leave();
    rtc.client = null
    rtc.joinState = false
    dispatch.app.setCurRtcInfo({
        audioTrack: null,
        videoTrack: null
    })
    console.log(`Exit Channel Successed~~~~`)
}

function closeAllTrack() {
    if (rtc.localAudioTrack) {
        rtc.localAudioTrack.stop();
        rtc.localAudioTrack.close();
        rtc.localAudioTrack = undefined;
    }

    if (rtc.localVideoTrack) {
        rtc.localVideoTrack.stop();
        rtc.localVideoTrack.close();
        rtc.localVideoTrack = undefined;
    }

    if (rtc.localCreenTrack) {
        rtc.localCreenTrack.stop();
        rtc.localCreenTrack.close();
        rtc.localCreenTrack = undefined;
    }
}

export {
    rtc,
    joinChannel,
    closeTrack,
    joinTrack,
    leave,
    closeAllTrack,
    checkCamera,
    checkMicrophones,
    getCameras,
    getCameraTrackLabel,
    setCameraDevice,
    getMicrophones,
    getMicrophoneTrackLabel,
    setMicrophoneDevice,
    setCameraResolution,
    switchTrack,
}
