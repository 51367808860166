import {BigNumber, ethers} from "ethers";
import {abi_auction, abi_token} from "@/utils/abi";
import {getConfig} from "./common";
import {getGasConfig} from "@/utils/gas";

function addFloat() {
    if (!arguments) {
        return undefined;
    }
    var minVal = Math.min(...arguments).toString(); // 取最小值
    var minValBs = 1; // 倍数
    var jsValLength = 0;// 计算位数
    if (minVal.indexOf('.') === 1) {
        jsValLength = minVal.split('.')[1].length > 0 ? minVal.split('.')[1].length : 0;
    }
    var maxVal = Math.max(...arguments).toString(); // 取最大值
    if (maxVal.indexOf('.') === -1 && maxVal.length > jsValLength) {
        jsValLength = maxVal.length;
    }
    minValBs = Math.pow(10, jsValLength);// 幂运算 计算倍数

    var sum = 0;
    for (var i = 0; i < arguments.length; i++) {
        var _v = arguments[i];
        var _vStr = arguments[i].toString();
        var _vBsc = 1;
        if (_vStr.indexOf('.') === 1) {
            var _vInt = parseInt(_vStr.replace('.', ''));
            var _vLength = _vStr.split('.')[1].length > 0 ? _vStr.split('.')[1].length : 0;
            var _vBs = Math.pow(10, jsValLength - _vLength);
            sum += _vInt * _vBs;
        } else {
            var _vLength = _vStr.length > 1 ? _vStr.length : 0;
            var _vBs = Math.pow(10, jsValLength);
            sum += _v * _vBs;
        }
    }
    return sum / minValBs;
}

const getLfgAllowance = (embeddedWallet, spender, lfgTokenAddr) => {
    return new Promise(async (resolve, reject) => {
        try {
            const provider = await embeddedWallet.getEthersProvider();

            const contract = new ethers.Contract(lfgTokenAddr, abi_token, provider);
            let allowance = await contract.allowance(embeddedWallet.address, spender);

            console.log(`[lfgAllowance] for[${spender}] value[${ethers.utils.formatEther(allowance)}]`);
            resolve(allowance);

        } catch (e) {
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

const lfgApprove = (wallets, amount) => {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {lfgTokenAddr, pfpAuctionAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

            console.log(embeddedWallet);

            const provider = await embeddedWallet.getEthersProvider();

            const signer = provider.getSigner(); // ethers signer object

            const contract = new ethers.Contract(lfgTokenAddr, abi_token, provider).connect(signer);

            contract.populateTransaction.approve(pfpAuctionAddr, amount, await getGasConfig(signer)).then(unsignedTx => {
                signer.sendTransaction(unsignedTx).then(
                    resp => {
                        console.log(`[lfgApprove] for[${pfpAuctionAddr}] value[${ethers.utils.formatEther(amount)}]`);
                        resolve(resp);
                    }
                ).catch(
                    e => reject(e)
                );
            }).catch(e => {
                reject(e);
            });
        } catch (e) {
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}


async function getAuctionContract(wallets, isWriter) {

    const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

    const {pfpAuctionAddr, lfgTokenAddr, chainId} = await getConfig();
    console.log("[auction contract Addr]", pfpAuctionAddr, "[chainId]", chainId);
    //console.log(embeddedWallet);
    await embeddedWallet.switchChain(chainId);

    const provider = await embeddedWallet.getEthersProvider();

    if (isWriter) {
        const signer = provider.getSigner();

        const gasConfig = await getGasConfig(signer);
        gasConfig.gasLimit = 500000;

        const contract = new ethers.Contract(pfpAuctionAddr, abi_auction, provider).connect(signer);
        return {contract, addr: embeddedWallet.address, gasConfig, embeddedWallet, lfgTokenAddr};
    } else {
        const contract = new ethers.Contract(pfpAuctionAddr, abi_auction, provider);
        return {contract, addr: embeddedWallet.address, embeddedWallet, lfgTokenAddr};
    }
}

// async function setItem(addr, tokenId, initPrice, isNative){

//     const auction = await (await ethers.getContractFactory("PFPAuction")).attach(auctionAddr);

//     const startTime = Math.floor(Date.now() / 1000) + 60;
//     const endTime = startTime + 600;
//     const defer = 120;

//     const initialPrice = ethers.utils.parseEther(initPrice);

//     console.debug(`start[${startTime}] endTime[${endTime}] defer[${defer}] initPrice[${initPrice}]`);

//     const txSetItem = await auction.setItemForAuction(
//         addr, tokenId, initialPrice, isNative, startTime, endTime, defer);
//     console.debug("txSetItem", txSetItem);
// }

const bid = (wallets, tokenAddr, tokenId, expectedPrice, slippage, maticBalance, coinType) => {
    //console.log(wallets);
    return new Promise(async (resolve, reject) => {

        try {
            const {contract, embeddedWallet, gasConfig, lfgTokenAddr} = await getAuctionContract(wallets, true);
            const onChinaNextPrice = await contract.getNextPrice(tokenAddr, tokenId);

            let bigNumberExpectedPrice = onChinaNextPrice; //ethers.utils.parseEther(expectedPrice.toString());

            let nTopPrice = slippage === 0
                ? bigNumberExpectedPrice.toBigInt()
                : ethers.utils.parseEther((Number(ethers.utils.formatEther(bigNumberExpectedPrice)) * (1 + slippage * 0.01)).toString()).toBigInt();

            let nSafeMaticValue = 0n;

            if (coinType === 0) {
                let currentAllowance = await getLfgAllowance(embeddedWallet, contract.address, lfgTokenAddr);
                console.log('[getLfgAllowance]', {currentAllowance: currentAllowance.toBigInt(), nTopPrice});
                if (currentAllowance.toBigInt() < nTopPrice) {
                    await lfgApprove(wallets, ethers.utils.parseEther(Number.MAX_SAFE_INTEGER.toString())); //approve lfg for auction As MANY as Possible
                }
            } else {
                let nMaticBalance = ethers.utils.parseEther(Math.max(0, maticBalance - 0.1).toString()).toBigInt();
                nSafeMaticValue = nTopPrice <= nMaticBalance ? nTopPrice : nMaticBalance;
            }

            console.log('[call bid]', {
                tokenAddr,
                tokenId,
                onChinaNextPrice,
                expectedPrice,
                slippage,
                nSafeMaticValue,
                gasConfig
            });

            const bidTx = await contract.populateTransaction.bid(tokenAddr, tokenId, bigNumberExpectedPrice, slippage, coinType === 0 ? {} : {
                value: BigNumber.from(nSafeMaticValue)
            });
            console.log('[bidTx]', bidTx);

            const gasForTx = await contract.signer.estimateGas(bidTx);
            console.log('[gasForTx]', gasForTx.toBigInt());

            if (gasForTx.toBigInt() * 2n > (gasConfig.gasLimit || 0)) {
                gasConfig.gasLimit = BigNumber.from(gasForTx.toBigInt() * 2n);
                console.log('[gasConfig.gasLimit overrided]', gasConfig);
            }

            contract.bid(tokenAddr, tokenId, bigNumberExpectedPrice, slippage, coinType === 0 ? gasConfig : {
                ...gasConfig,
                value: BigNumber.from(nSafeMaticValue)
            }).then(res => {
                console.log("[bid] ", res);
                resolve(res)
            }).catch(e => {
                reject(e)
            });

        } catch (error) {
            console.log("[bid exception]", error);
            if (error.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(error);
            }
        }

    })
}

const bidBatch = (wallets, batchObj, slippage, maticBalance) => {

    //console.log(wallets, batchObj);
    const {lfg, matic} = batchObj;

    return new Promise(async (resolve, reject) => {

        try {
            const {contract, embeddedWallet, gasConfig, lfgTokenAddr} = await getAuctionContract(wallets, true);

            let lfgCost = ethers.utils.parseEther((lfg?.val * (1 + slippage * 0.01)).toString());

            if (lfg?.tokenAddrs.length !== 0 && lfg?.val !== 0) {
                let currentAllowance = await getLfgAllowance(embeddedWallet, contract.address, lfgTokenAddr);

                if (currentAllowance < lfgCost) {
                    await lfgApprove(wallets, ethers.utils.parseEther(Number.MAX_SAFE_INTEGER.toString())); //approve lfg for auction As MANY as Possible
                }
            }

            let safeMaticValue = Math.min(matic?.val * (1 + slippage * 0.01), Math.max(0, maticBalance - 0.1));

            let lfgBidCount = lfg?.tokenAddrs.length;
            let maticBidCount = matic?.tokenAddrs.length;

            console.log(
                '[bidBatch] [lfg bids]', lfgBidCount, '[matic bids]', maticBidCount,
                '[MaxLfgCost]', ethers.utils.formatEther(lfgCost),
                '[MaticValue]', safeMaticValue,
                '[slippage]', slippage);

            const tokenAddrs = [...lfg?.tokenAddrs, ...matic?.tokenAddrs];
            const tokenIds = [...lfg?.tokenIds, ...matic?.tokenIds];
            const expectedSpendings = [...lfg?.expectedSpendings, ...matic?.expectedSpendings];
            console.log(tokenAddrs, tokenIds, expectedSpendings, ethers.utils.parseEther(matic?.val.toString()));

            const bidTx = await contract.populateTransaction.bidBatch(tokenAddrs, tokenIds, expectedSpendings, slippage, maticBidCount === 0 ? {} : {
                value: ethers.utils.parseEther(safeMaticValue.toString())
            });
            console.log('[bidTx]', bidTx);

            const gasForTx = await contract.signer.estimateGas(bidTx);
            const nGasForTx = gasForTx.toBigInt();
            console.log('[gasForTx]', nGasForTx);

            const nIncreasedGasForTx = nGasForTx + nGasForTx / 2n;
            if (nIncreasedGasForTx > (gasConfig.gasLimit || 0)) {
                gasConfig.gasLimit = BigNumber.from(nIncreasedGasForTx);
                console.log('[gasConfig.gasLimit overrided]', nIncreasedGasForTx);
            }

            contract.bidBatch(tokenAddrs, tokenIds, expectedSpendings, slippage, maticBidCount === 0 ? gasConfig : {
                ...gasConfig,
                value: ethers.utils.parseEther(safeMaticValue.toString())
            }).then(res => {
                resolve(res)
            }).catch(e => {
                reject(e)
            })
        } catch (error) {
            console.log("[bidBatch] exception: " + error);
            if (error.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(error);
            }
        }
    })
}


const bidBatchPrice = (wallets, lists) => {
    console.log(wallets);

    return new Promise(async (resolve, reject) => {
        if (!(lists && lists?.length)) reject('')
        let tokenAddrs = [], tokenIds = []
        lists?.map(item => {
            const {tokenAddr, tokenId} = item?.nftSimpleData;
            tokenAddrs.push(tokenAddr)
            tokenIds.push(tokenId)
        })
        try {
            const {contract, addr, gasConfig} = await getAuctionContract(wallets, true);
            console.log(tokenAddrs, tokenIds);
            const nextPrice = await contract.getNextPriceBatch(tokenAddrs, tokenIds);
            console.log(nextPrice);
            resolve(ethers.utils.formatUnits(nextPrice))
        } catch (error) {
            reject(error)
        }

    })
}

const claim = (wallets, tokenAddr, tokenId) => {
    return new Promise(async (resolve, reject) => {

        try {
            const {contract, addr, gasConfig} = await getAuctionContract(wallets, true);
            console.log(tokenAddr, tokenId, gasConfig);
            contract.claim(tokenAddr, tokenId, gasConfig).then(res => {
                resolve(res)
            }).catch(e => {
                reject(e)
            })
        } catch (error) {
            reject(error)
        }

    })
    // const auction = await (await ethers.getContractFactory("PFPAuction")).attach(auctionAddr);

    // const txClaim = await auction.claim(addr, tokenId);
    // console.debug("txClaim", txClaim);
}

const claimBatch = (wallets, tokenAddrs, tokenIds) => {
    return new Promise(async (resolve, reject) => {

        try {
            const {contract, addr, gasConfig} = await getAuctionContract(wallets, true);
            console.log(tokenAddrs, tokenIds, gasConfig);
            const claimTx = await contract.populateTransaction.claimBatch(tokenAddrs, tokenIds, gasConfig);
            console.log('[claimTx]', claimTx);

            const gasForTx = await contract.signer.estimateGas(claimTx);
            const nGasForTx = gasForTx.toBigInt();
            console.log('[gasForTx]', nGasForTx);

            const nIncreasedGasForTx = nGasForTx + nGasForTx / 2n;
            if (nIncreasedGasForTx > (gasConfig.gasLimit || 0)) {
                gasConfig.gasLimit = BigNumber.from(nIncreasedGasForTx);
                console.log('[gasConfig.gasLimit overrided]', nIncreasedGasForTx);
            }

            contract.claimBatch(tokenAddrs, tokenIds, gasConfig).then(res => {
                resolve(res)
            }).catch(e => {
                reject(e)
            })
        } catch (error) {
            reject(error)
        }

    })
    // const auction = await (await ethers.getContractFactory("PFPAuction")).attach(auctionAddr);

    // const txClaim = await auction.claim(addr, tokenId);
    // console.debug("txClaim", txClaim);
}

const getBidStatus = (wallets, tokenId) => {
    return new Promise(async (resolve, reject) => {

        try {
            const {contract, addr, gasConfig} = await getAuctionContract(wallets, true);
            console.log(addr, tokenId, gasConfig);
            contract.isAuctionOver(addr, tokenId).then(res => {
                resolve(res)
            }).catch(e => {
                reject(e)
            })
        } catch (error) {
            reject(error)
        }

    })
    // const auction = await (await ethers.getContractFactory("PFPAuction")).attach(auctionAddr);

    // const isAuctionOver = await auction.isAuctionOver(addr, tokenId);
    // const endTime = await auction.auctionEndTime(addr, tokenId);
    // const nextPrice = await auction.getNextPrice(addr, tokenId);

    // console.debug(`[Status] isAuctionOver[${isAuctionOver}] endTime[${endTime}] nextPrice[${nextPrice}]`);
}


/*
setItem(nativeNFTAddr, 12, "0.1", true)
  .then(() => process.exit(0))
  .catch((error) => {
    console.error(error);
    process.exit(1);
  });
  */

/*
getItem(nativeNFTAddr, 12)
.then(() => process.exit(0))
.catch((error) => {
  console.error(error);
  process.exit(1);
});*/

/*
claim(nativeNFTAddr, 12)
.then(() => process.exit(0))
.catch((error) => {
  console.error(error);
  process.exit(1);
});
*/

/*
withdrawProceeds('0x06963521455e072618F4A5C13533F9f7B474AA77', true, "0")
.then(() => process.exit(0))
.catch((error) => {
  console.error(error);
  process.exit(1);
});
*/

export {
    claim,
    getBidStatus,
    bid,
    bidBatch,
    bidBatchPrice,
    claimBatch,
    addFloat,
    getLfgAllowance,
    lfgApprove,
}
