import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "@/common/ButtonFactory";
import CustomIcon from "@/common/CustomIcon";
import AntButton from "@/common/AntButton";

const PcPWAIntro = ({
    closeAInstall,
    type = 1,
}) => {
    const {t, i18n} = useTranslation();
    const [leftSec,setLeftSec] = useState(5)
    useEffect(()=>{
        let timer = null
        if(!timer){
            timer = setInterval(()=>{
                setLeftSec(leftSec=>{
                    const _leftSec = --leftSec
                    if(_leftSec === 0){
                        clearInterval(timer)
                        closeAInstall()
                    }
                    return leftSec--
                })
            },1000)
        }
        return ()=>{
            timer && clearInterval(timer)
        }
    },[])
    return (
        <>
            {
                type === 2 ?
                <div className={`${s.momentWrap} flex_center_start_col`}>
                    <div className={`${s.title} flex_center_center`}>
                        {t('Install Now to Start Using')}
                    </div>
                    <div className={`${s.imageRoot} flex_center_center`}>
                        <img className={`${s.image}`} src="/UI/Picture/quicksetup.png"></img>
                    </div>
                    <div className={`${s.tips}`}>
                        {t('Install TurnUp on Your Device')}
                    </div>
                    <AntButton className={`${s.commonBtn} btn_public`} onClick={closeAInstall}>
                        <div className={`fs16 fb color-gray`}>
                            {`${t('Go')}(${leftSec})`}
                        </div>
                    </AntButton>
                    <CustomIcon 
                        className={`momentCloseBtn`} 
                        width={40} 
                        height={40} 
                        imgName={`Picture/UI_Picture_Icon_Close_02`}
                        onClick={() => {
                            closeAInstall();
                        }} />
                </div>:
                <div className={s.wrap}>
                    <div className="flex_center_center">
                        <img width={'80%'} src="/UI/Picture/quicksetup.png"></img>
                    </div>
                    <div className="mt10">
                        {t('Install TurnUp on Your Device')}
                    </div>
                    <div className="modelBottomBtn">
                        <ButtonFactory size={21} scale={1.5} onClick={closeAInstall}>

                            <span className="fs20 color-black">{t('Go')}({leftSec})</span>
                        </ButtonFactory>
                    </div>
                </div>
            }
        </>
    )
}
export default memo(PcPWAIntro);
