import React, {memo, useState, useMemo, useRef} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import {Button, Slider, Checkbox} from "antd";
import {unit} from "@/utils/common";
import {useTranslation, Trans} from 'react-i18next';
import AntButton from "@/common/AntButton";
import {AutoStrangeRule} from "@/utils/strangeRule";
import {getGasType, setGasType} from "@/utils/gas";

const GasSetting = (
    {
        closeHandle,
        type = 1, //1:common, 2:moment
    }
) => {
    const {t, i18n} = useTranslation();

    const [gasTypeStatus, setGasTypeStatus] = useState(getGasType());

    const changeLowGas = (e) => {
        setGasType('low');
        setGasTypeStatus('low');
    }
    const changeSuggestedGas = (e) => {
        setGasType('fast');
        setGasTypeStatus('fast');
    }

    return (
        <>
            {
                type === 2 ? 
                <div className={`${s.momentWrap} flex_center_start_col`}>
                    <div className={s.momentTitle}>
                        <CustomIcon width={18} className="mr5" height={18} imgName={'Button/UI_Button_FilterIcon_02'}/>
                        {AutoStrangeRule(t('Gas Mode'))}
                    </div>
                    <div className={`${s.momentDrawCon}`}>
                        <div className={`${s.settingLine} flex_center_start mb10`}>
                            <div className={`${s.settingLineLeft} ${s.lineTitle}`}>
                                {t('Gas option')}
                            </div>
                            <div className={`${s.settingLineRight} ${s.lineTitle}`}>
                                {t('Time')}
                            </div>
                        </div>
                        <div className={`${s.settingLine} flex_center_start mb10`}>
                            {
                                gasTypeStatus === 'low' ? 
                                <CustomIcon 
                                    imgName={`Picture/UI_Picture_Icon_Tick_w_01`} 
                                    className={s.checkBox}
                                    width={14} 
                                    height={14}/>:
                                <div className={`${s.uncheckedBox} ${s.checkBox}`} onClick={(e) => {
                                    changeLowGas(e);
                                }}>
                                </div>
                            }
                            <div className={`${s.settingLineLeft} ${s.lineText}`}>
                                {t('Low')}
                            </div>
                            <div className={`${s.settingLineRight} ${s.lineText}`}>
                                {t('30s+')}
                            </div>
                        </div>
                        <div className={`${s.settingLine} flex_center_start mb40`}>
                            {
                                gasTypeStatus === 'fast' ? 
                                <CustomIcon 
                                    imgName={`Picture/UI_Picture_Icon_Tick_w_01`} 
                                    className={s.checkBox}
                                    width={14} 
                                    height={14}/>:
                                <div className={`${s.uncheckedBox} ${s.checkBox}`} onClick={(e) => {
                                    changeSuggestedGas(e);
                                }}>
                                </div>
                            }
                            <div className={`${s.settingLineLeft} ${s.lineText}`}>
                                {t('Suggested')}
                            </div>
                            <div className={`${s.settingLineRight} ${s.lineText}`}>
                                {t('15s-30s')}
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className={s.wrap}>
                    <div className={s.title}>
                        <CustomIcon width={25} className="mr5" height={30} imgName={'Button/UI_Button_UpAndDown_01'}/>
                        {AutoStrangeRule(t('Gas Mode'))}
                    </div>
                    <div className={s.drawCon}>
                        <div className={`${s.settingLine} flex_center_start mb10`}>
                            <div className={s.settingLineLeft}>
                                {t('Gas option')}
                            </div>
                            <div className={s.settingLineRight}>
                                {t('Time')}
                            </div>
                        </div>
                        <div className={`${s.settingLine} flex_center_start mb10`}>
                            <div className={s.settingLineLeft}>
                                <Checkbox onChange={changeLowGas} className="hireCheckBox"
                                        checked={gasTypeStatus === 'low'}>
                                    <span className="ml5">{t('Low')}</span>
                                </Checkbox>
                            </div>
                            <div className={s.settingLineRight}>
                                {t('30s+')}
                            </div>
                        </div>
                        <div className={`${s.settingLine} flex_center_start mb10`}>
                            <div className={s.settingLineLeft}>
                                <Checkbox onChange={changeSuggestedGas} className="hireCheckBox"
                                        checked={gasTypeStatus === 'fast'}>
                                    <span className="ml5">{t('Suggested')}</span>
                                </Checkbox>
                            </div>
                            <div className={s.settingLineRight}>
                                {t('15s-30s')}
                            </div>
                        </div>
                    </div>
                    {/*<div className={s.bottom}>
                        <AntButton className={s.black} onClick={reset}>{t('RESET')}</AntButton>
                        <AntButton className="btn_public" onClick={apply}>{t('APPLY')}</AntButton>
                    </div>*/}
                </div>
            }
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(GasSetting));
