import React, { useMemo, useState, memo, useRef } from "react";
import { connect } from "react-redux";
import s from "./index.module.less";
import HomeTips from "src/common/HomeTips";
import { loginFn, borwserOS, borwserPlatform, unit, getConfig, taPush } from "@/utils/common";
import { StoreContext } from "@/contexts/StoreContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Button, message } from "antd";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import { closeAddScreen, envConfig } from "@/utils/env";
import Banner from "src/common/Banner";
import MobileBG from "@/assets/video/HomepageVideo.mp4";
import SiteBG from "@/assets/video/Homepage_Web.mp4";
import SiteBGFrame from "@/assets/images/bgImg/Homepage_WebFrame1.webp";
import MobileBGFrame from "@/assets/images/bgImg/HomepageVideoFrame1.webp";

import CustomIcon from "src/common/CustomIcon";
import bg from "@/assets/images/bgImg/UI_Background_Start_US-GTA.webp";
import { useTranslation, Trans } from 'react-i18next';
import { 
    mainHomePath,
    gameMomentClubDetailPath,
    gameMomentClubExplorePath,
    gameMomentClubPath,
} from "@/routes/config";
import ButtonFactory from "@/common/ButtonFactory";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import AntModal from "@/common/AntModal";
import txtImg from "@/assets/images/bgImg/UI_Picture-Web_Word_01.png"
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { 
    defaultMemeSwitchVideoParams, 
    momentClubList,
} from "@/utils/momentClub";
import PcPWAIntro from "@/model/PcPWAIntro";
// import LoginSwiper from "@/common/LoginSwiper";
const locationUserInfo = JSON.parse(localStorage.getItem('_TT_userInfo'));
const partnerData = [
    {
        "icon": "UI_Button-Logo_AIX",
        "width": 209,
        "height": 64,
        "url": "https://aigentx.xyz/"
    },
    {
        "icon": "UI_Button-Logo_Arena Games",
        "width": 150,
        "height": 64,
        "url": "https://arenavs.com/"
    },
    {
        "icon": "UI_Button-Logo_Ave.ai",
        "width": 150,
        "height": 93,
        "url": "http://ave.ai/"
    },
    {
        "icon": "UI_Button-Logo_BIGA",
        "width": 160,
        "height": 40,
        "url": "https://bigarcade.org/"
    },
    {
        "icon": "UI_Button-Logo_BYTE_CITY",
        "width": 250,
        "height": 80,
        "url": "https://www.byte.city/"
    },
    {
        "icon": "UI_Button-Logo_Carv",
        "width": 261,
        "height": 50,
        "url": "https://carv.io/"
    },
    {
        "icon": "UI_Button-Logo_COQ_INU",
        "width": 150,
        "height": 108,
        "url": "https://coqinu.com/"
    },
    {
        "icon": "UI_Button-Logo_DEW",
        "width": 150,
        "height": 41,
        "url": "https://dew.gg/polygon/collections"
    },
    {
        "icon": "UI_Button-Logo_Element",
        "width": 238,
        "height": 58,
        "url": "https://element.market/"
    },
    {
        "icon": "UI_Button-Logo_FUD_THE_PUG",
        "width": 150,
        "height": 64,
        "url": "https://fudthepug.com/"
    },
    {
        "icon": "UI_Button-Logo_GAIMIN",
        "width": 217,
        "height": 64,
        "url": "https://www.gaimin.io/"
    },
    {
        "icon": "UI_Button-Logo_Galxe",
        "width": 292,
        "height": 53,
        "url": "https://www.galxe.com/"
    },
    {
        "icon": "UI_Button-Logo_Gam3sgg",
        "width": 354,
        "height": 36,
        "url": "https://gam3s.gg/"
    },
    {
        "icon": "UI_Button-Logo_Gameta",
        "width": 296,
        "height": 64,
        "url": "https://www.gameta.pro/#/"
    },
    {
        "icon": "UI_Button-Logo_Hyperspace",
        "width": 313,
        "height": 47,
        "url": "https://hyperspace.xyz/"
    },
    {
        "icon": "UI_Button-Logo_Intract",
        "width": 233,
        "height": 40,
        "url": "https://www.intract.io/"
    },
    {
        "icon": "UI_Button-Logo_Kuroro_Beasts",
        "width": 150,
        "height": 64,
        "url": "https://www.kuroro.com/"
    },
    {
        "icon": "UI_Button-Logo_Magic_Square",
        "width": 150,
        "height": 83,
        "url": "https://magic.store/"
    },
    {
        "icon": "UI_Button-Logo_METAPLEX",
        "width": 356,
        "height": 40,
        "url": "https://www.metaplex.com/"
    },
    {
        "icon": "UI_Button-Logo_NFPrompt",
        "width": 313,
        "height": 52,
        "url": "https://nfprompt.io/"
    },
    {
        "icon": "UI_Button-Logo_NFTDaily",
        "width": 150,
        "height": 81,
        "url": "https://thenftdaily.co.uk/"
    },
    {
        "icon": "UI_Button-Logo_Polygon",
        "width": 253,
        "height": 64,
        "url": "https://polygon.technology/"
    },
    {
        "icon": "UI_Button-Logo_Pudgy_Penguins",
        "width": 180,
        "height": 70,
        "url": "https://www.pudgypenguins.com/"
    },
    {
        "icon": "UI_Button-Logo_QuickSwap",
        "width": 335,
        "height": 103,
        "url": "https://quickswap.exchange/#/"
    },
    {
        "icon": "UI_Button-Logo_SUPRA",
        "width": 233,
        "height": 50,
        "url": "https://supra.com/"
    },
    {
        "icon": "UI_Button-Logo_TaskOn",
        "width": 150,
        "height": 81,
        "url": "https://taskon.xyz/"
    },
    {
        "icon": "UI_Button-Logo_The_Pixels",
        "width": 150,
        "height": 81,
        "url": "https://t.me/the_pixels_bot"
    },
    {
        "icon": "UI_Button-Logo_TON",
        "width": 168,
        "height": 64,
        "url": "https://ton.org/"
    },
    {
        "icon": "UI_Button-Logo_ULTIVERSE",
        "width": 335,
        "height": 128,
        "url": "https://ultiverse.io/"
    },
    {
        "icon": "UI_Button-Logo_UniSat",
        "width": 244,
        "height": 51,
        "url": "https://unisat.io/"
    },
    {
        "icon": "UI_Button-Logo_Waypoint",
        "width": 150,
        "height": 81,
        "url": "https://discord.com/invite/waypointgaming"
    },
    {
        "icon": "UI_Button-Logo_Wormhole",
        "width": 462,
        "height": 72,
        "url": "https://wormhole.com/"
    },
    {
        "icon": "UI_Button-Logo_Zen_Academy",
        "width": 150,
        "height": 108,
        "url": "https://discord.com/invite/zenacademy"
    },
    {
        "icon": "UI_Button-Logo_ZetaChain",
        "width": 284,
        "height": 53,
        "url": "https://www.zetachain.com/"
    }
]
const partnerDataGrop = [
    {
        itemIndx: [0, 1, 2, 3]
    }, {
        itemIndx: [4, 5, 6, 7]
    }, {
        itemIndx: [8, 12, 10]
    }, {
        itemIndx: [11, 9, 33]
    }, {
        itemIndx: [13, 15, 19]
    }, {
        itemIndx: [31, 17, 16]
    }, {
        itemIndx: [32, 20, 18, 22]
    }, {
        itemIndx: [25, 23, 21]
    }, {
        itemIndx: [26, 27, 28,30]
    }, {
        itemIndx: [14, 29, 24]
    }
]
const Login = (
    {
        userInfo,
        setUserInfo,
        setWebIMUserInfo,
        setShowBalance,
        token,
        setInitTopUpAmount,
        hideNSFW,
        setCurrentMomentClubId,
        setSwitchVideoList,
        setSwitchVideoListCursor,
    }
) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { ready, user, login, getAccessToken, logout } = usePrivy();
    const { wallets } = useWallets();
    const { t, i18n } = useTranslation();
    const { db } = React.useContext(StoreContext);
    const userInfoObj = JSON.stringify(userInfo) !== '{}' ? userInfo?.selfData : db.get('userInfo') || locationUserInfo;
    // useEffect(()=>{
    //     setUserInfo(userInfoObj)
    // },[userInfoObj])

    // const [openDialog, setOpenDialog] = useState(false);
    const [_hasLogined, _setHasLogined] = useState(userInfoObj?.userId ? true : false);
    const [_hasInvite, _setHasInvite] = useState(userInfoObj?.inviteCodeCheck);
    const [_hasLink, _setHasLink] = useState(userInfoObj?.platform?.uid);
    const [_payEntryFee, _setPayEntryFee] = useState(userInfoObj?.payEntryFee);
    const [_buyFirstKey, _setBuyFirstKey] = useState(userInfoObj?.buyFirstKey);
    const [_balance, _setBalance] = useState(userInfoObj?.balance);
    const [loading, setLoading] = useState(false);
    const [loadingAfterPrivy, setLoadingAfterPrivy] = useState(false);
    const [updateLoadingCount, setUpdateLoadingCount] = useState(0);
    const [authToken, setAuthToken] = useState('');
    const [swiper, setSwiper] = useState(null);
    const [showTips,setShowTips] = useState(false)
    const [loadingAfterPrivyLaterCount, setLoadingAfterPrivyLaterCount] = useState(0);
    const loadingAfterPrivyTimestampRef = useRef(0);

    const [showInstallTips,setShowInstallTips] = useState(false)

    const closeAInstall = ()=>{
        setShowInstallTips(false)
        installApp()
    }

    const walletReady = useMemo(() => {
        return ready && wallets.length
    }, [ready, user])

    const installApp = async () => {
        /*
        const promptEvent = window.deferredPrompt;
        if (!promptEvent) {
            return;
        }
        promptEvent.prompt();

        const result = await promptEvent.userChoice;
        window.deferredPrompt = null;*/

        window.open('/?pswutlzoq=install', '_self');
    }

    const goMomentClub = () => {
        let params = defaultMemeSwitchVideoParams;
        loadingAfterPrivyTimestampRef.current = (new Date().getTime()) + 500;
        setLoadingAfterPrivyLaterCount(loadingAfterPrivyLaterCount + 1);
        momentClubList({
            rankType: params.rankType,
            coinId: params.coinId,
            priceRange: params.priceRange,
            progressRange: params.progressRange,
            lpRange: params.lpRange,
            onlyFavorite: params.onlyFavorite,
            onlyHeld: params.onlyHeld,
            onlyLive: params.onlyLive,
            isAsc: params.isAsc,
            cursor: ""
        }).then(res => {
            loadingAfterPrivyTimestampRef.current = 0;
            let _list = []; 
            if (res?.clubList?.length > 0){
                for (let i = 0; i < res?.clubList?.length; i++){
                    let stateCode = res?.clubList[i].profile.momentAuditCode;
                    if (stateCode !== 2 || (stateCode === 2 && !hideNSFW)){
                        _list.push({
                            clubId: res?.clubList[i].userId,
                            momentAuditCode: stateCode,
                            videoId: res?.clubList[i].videoId,
                            videoUrlMap: res?.clubList[i].videoUrlMap,
                            coverUrl: res?.clubList[i].profile.avatarUrl,
                            videoOriHeight: res?.clubList[i].videoOriHeight,
                            videoOriWidth: res?.clubList[i].videoOriWidth,
                            videoFirstFrameUrl: res?.clubList[i].videoFirstFrameUrl,
                        });
                    }
                }
            }
            let index = -1;
            if (_list?.length > 0){
                for (let i = 0; i < _list?.length; i++){
                    if (_list[i]?.momentAuditCode !== 3){
                        index = i;
                        break;
                    }
                }
            }
            setSwitchVideoList(_list);
            if (res?.nextCursor === ""){
                setSwitchVideoListCursor("-1");
            }
            else{
                setSwitchVideoListCursor(res?.nextCursor);
            }
            if (index >= 0){
                setCurrentMomentClubId(_list[index].clubId);
                navigate(`${gameMomentClubDetailPath}`,{replace:true,state: {backPath:gameMomentClubExplorePath}});
            }
            else{
                navigate(gameMomentClubExplorePath);
            }
        }).catch(e => {
            console.error(e);
            loadingAfterPrivyTimestampRef.current = 0;
            navigate(gameMomentClubExplorePath);
        })
    }

    const loginSuccessNavigate = () => {
        const isInMomentGame = location.pathname.startsWith(gameMomentClubPath);
        if (window.isMomentsLink || isInMomentGame){
            goMomentClub();
        }
        else{
            navigate(mainHomePath, { replace: true });
        }
    }

    useEffect(() => {

        if (walletReady && !authToken && !_hasLogined) {
            getAccessToken().then(res => {
                setAuthToken(res);
                sign(res)
            }).catch(e => {
                console.error('Privy Login Failed', e);
            });


        }
        if (_hasLogined) {
            loginSuccessNavigate();
        }
    }, [walletReady, _hasLogined])


    const { userId } = useMemo(() => {
        return userInfo || {}
    }, [userInfo])


    const isTopApp = useMemo(() => {
        if (window.navigator && (window.matchMedia("(display-mode: standalone)").matches || !0 === window.navigator.standalone || window.navigator.userAgent.includes("standalone"))) {
            return true
        }
        if (JSON.stringify(userInfoObj) !== '{}' && JSON.stringify(userInfoObj) !== 'null' && _hasInvite && (!_hasLink || (!_payEntryFee || !_buyFirstKey))) {
            return true
        }
        return closeAddScreen
    }, [])

    // const openSignD = () => {
    //     !loading && setOpenDialog(true)
    // }

    const loginSuccessHandle = (res) => {
        // setOpenDialog(false)
        // setLoading(false)
        getConfig()
        const token = res?.data?.token;
        const userInfo = res?.data?.user;
        const initTopUpAmount = res?.data?.initTopUpAmount;
        const { inviteCodeCheck, payEntryFee, buyFirstKey, balance, userId, platform } = userInfo;
        window?.progressier?.add({
            id: userId.toString()
        })
        if (envConfig.taEnable) {
            window.ta.login(envConfig.taRegionId + "-" + userId.toString())
        }
        // const hasInvite = inviteCodeCheck;
        // const hasLink = platform?.uid ? true : false;
        setShowBalance(!(buyFirstKey && payEntryFee))
        db.update({
            userInfo: {
                ...userInfo,
                selfData: {
                    ...userInfo
                }
            },
            token: {
                token
            },
            initTopUpAmount,
            showBalance: !(buyFirstKey && payEntryFee),
            webIMUserInfo: {
                username: userId.toString()
            }
        });
        setUserInfo({
            ...userInfo,
            selfData: {
                ...userInfo
            }
        })
        // setInitTopUpAmount(initTopUpAmount)
        // _setHasLogined(true)
        // _setHasInvite(hasInvite)
        // _setHasLink(hasLink)
        // _setPayEntryFee(payEntryFee)
        // _setBuyFirstKey(buyFirstKey)
        // setInitTopUpAmount(initTopUpAmount)
        setWebIMUserInfo({
            username: userId.toString()
        })
        db.set()
        // window.opener = null;
        // window.open("","_self");
        // window.close();
    }

    // const closeDialog = () => {
    //     setOpenDialog(false)
    // }
    const sign = (_authToken) => {
        loginFn(_authToken, 4).then(res => {
            loginSuccessHandle(res)
        }).catch(e => {
            setLoading(false)
            console.error('Login Failed', e);
            logout()
            message.info({ content: "Login Failed, Please Check Your Network" })

        })

    }
    useEffect(() => {
        if (userId && walletReady) {
            loginSuccessNavigate();
        }
    }, [userId, walletReady])
    const loginPrive = async () => {
        if (userId && walletReady) {
            loginSuccessNavigate();
            return
        }
        // await logout()
        login().then().catch(e => {
            console.error('Privy Login Failed', e);
            logout()
        })
    }


    const iOSNotSafari = useMemo(() => {
        const os = borwserOS();
        const platform = borwserPlatform()
        if (os === 'iOS' && platform !== 'safari') {
            return true
        } else {
            return false
        }

    }, [])

    const [skip, setSkip] = useState(false)

    const isIos = useMemo(() => {
        const os = borwserOS();
        if (os === 'iOS') {
            return true;
        } else {
            return false;
        }
    }, [])

    const isAndroid = useMemo(() => {
        const os = borwserOS();
        if (os === 'android') {
            return true;
        } else {
            return false;
        }
    }, [])

    const isPc = useMemo(() => {
        return !isAndroid && !isIos && !isTopApp
    },[isAndroid,isIos,isTopApp])

    const scrollContentRef = useRef(null);

    const [isHovering, setIsHovering] = useState(false);

    // useEffect(() => {
    //     if(scrollContentRef.current) {
    //     const handleAnimationEnd = () => {
    //       console.log(`监听到···`);
    //       if (!isHovering) {
    //         scrollContentRef.current.appendChild(scrollContentRef.current.firstElementChild);
    //       }
    //     };
    
    //     scrollContentRef.current.addEventListener('animationiteration', handleAnimationEnd);
    
    
    //     return () => {
    //       scrollContentRef.current.removeEventListener('animationiteration', handleAnimationEnd);
    //     };
    //     }
    //   }, [isHovering]);

      const handleMouseEnter = () => {
        console.log(`Enter`);
        setIsHovering(true);
        scrollContentRef.current.style.animationPlayState = 'paused';
        
      };
    
      const handleMouseLeave = () => {
        console.log(`Leave`);
        setIsHovering(false);
        scrollContentRef.current.style.animationPlayState = 'running';
      };

    useEffect(() => {
        if (loadingAfterPrivy){
            setTimeout(() => {
                setUpdateLoadingCount(updateLoadingCount+1);
            }, 1000);
        }
    }, [loadingAfterPrivy, updateLoadingCount])

    useEffect(() => {
        if (loadingAfterPrivyTimestampRef.current > 0){
            let nowtime = new Date().getTime();
            if (loadingAfterPrivyTimestampRef.current <= nowtime){
                setLoadingAfterPrivy(true);
                loadingAfterPrivyTimestampRef.current = 0;
            }
            else{
                setTimeout(() => {
                    setLoadingAfterPrivyLaterCount(loadingAfterPrivyLaterCount + 1);
                }, 100);
            }
        }
        else{
            setLoadingAfterPrivy(false);
        }
    }, [loadingAfterPrivyLaterCount])

    return (
        <>
            {
                loadingAfterPrivy ?
                <div className={`${s.loadingWrap}`}>
                    <div className={`${s.logo}`} style={{backgroundImage: `url(/logo192.png)`}}>
                    </div>
                    <div className={`${s.text}`}>
                        {`Initializing.${updateLoadingCount%3>=1?'.':''}${updateLoadingCount%3>=2?'.':''}`}
                    </div>
                </div> :
                <div className={`${s.loginWrap } ${isPc ? s.pc : ''}`}>
                    <video className={s.bg} poster={isPc ? SiteBGFrame : MobileBGFrame} 
                        autoPlay={true} width={'100%'} height={'100%'}  muted loop playsInline
                        webkit-playsinline="true" x5-playsinline="true" x-webkit-airplay="deny" webkitwirelessvideoplaybackdisabled="true">
                        <source src={isPc?SiteBG:MobileBG} type="video/mp4" />
                    </video>
        
                    <div className={s.header}>
                        <div className={s.l}>
                            <CustomIcon imgName={'Picture/UI_Picture-TURNUP_Horizontal_Tilted_01'} width={isPc ? 289 * .85 : 289 * .5} height={isPc ? 100 * .85 : 100 * .5}></CustomIcon>
                        </div>
                        <div className={s.r}>
                            {/* <a href="https://www.google.com">
                                <CustomIcon className={isPc ? 'mr40' : 'mr10'} imgName={'Button/UI_Button_White_Discard_01'}  width={isPc ? 56 : 28} height={isPc ? 40 : 20}></CustomIcon>
                            </a> */}
                            <a href="https://t.me/justTURNUP">
                                <CustomIcon className={isPc ? 'mr40' : 'mr10'} imgName={'Button/UI_Button_White_TurnUp_01'} width={isPc ? 48 : 24} height={isPc ? 40 : 20}></CustomIcon>
                            </a>
                            <a href="https://twitter.com/turnupdotxyz">
                                <CustomIcon className="" imgName={'Button/UI_Button_White_Twitter_01'} width={isPc ? 44 : 22} height={isPc ? 40 : 20}></CustomIcon>
                            </a>
                        </div>
                    </div>
                    <div className={s.mid}>
                        {isPc ?
                            <div className={s.txtBg}>
                                <img src={txtImg} width={'80%'}></img>
                            </div>:
                            <>
                            <div className={s.t}>
                                <div>{t('Create, Launch,')}</div>
                                <div>{t('Trade, Play')}</div>
                            </div>
                            <div className={s.b}>
                                {/*<div className={s.bt}>{t('Play now and join')}</div>*/}
                                <div className={s.bm}>{t('Dual Nature Assets')}</div>
                                <div className={s.bb}>{t('What’s your D.N.A?')}</div>
                            </div>
                            </>
                        }
                        
                        {isPc && <div className={s.startBtn}>
                                <ButtonFactory
                                    size={33}
                                    scale={5}
                                    className="zoom"
                                    onClick={() => {
                                        setShowInstallTips(true)
                                        // installApp()
                                    }}
                                >{t('START')}</ButtonFactory>
                            </div>}
                            {/* <LoginSwiper/> */}
                    </div>
        
                    <div className={s.foot}>
                        <div className={s.ft}>
                            <div className={s.ftl}>{t('Our Partners:')}</div>
                            {!isPc && <div className={s.ftr}>
                                {(!isTopApp || iOSNotSafari) && !skip ? (
                                    <ButtonFactory
                                        size={33}
                                        scale={2.4}
                                        className="zoom"
                                        onClick={() => installApp()}
                                    >
                                        {t('START')}
                                    </ButtonFactory>
                                ) : (
                                    <ButtonFactory
                                        size={33}
                                        scale={2.4}
                                        className="zoom"
                                        onClick={() => {
                                            taPush('click_start_button');
                                            loginPrive();
                                        }}
                                    >
                                        {t('START')}
                                    </ButtonFactory>
                                )}
                            </div>}
                        </div>
                        {   
                            isPc ? <div className={s.iconList} ref={scrollContentRef} 
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>
                                
                                {partnerData.map((i,ind) => {
                                    const { icon, width, height, url } = i;
                                    return (
                                            <a key={ind} className={s.item} href={url} >
                                                <CustomIcon
                                                    key={i}
                                                    imgName={`Button/Logo/${icon}`}
                                                    width={width / 2}
                                                    height={height / 2} />
                                            </a>
                                    )
                                    
                                })}
                                {partnerData.map((i,ind) => {
                                    const { icon, width, height, url } = i;
                                    return (
                                            <a key={`s${ind}`} className={s.item} href={url} >
                                                <CustomIcon
                                                    key={i}
                                                    imgName={`Button/Logo/${icon}`}
                                                    width={width / 2}
                                                    height={height / 2} />
                                            </a>
                                    )
                                    
                                })}
                            </div>:
                            <div className={s.fb}>
                            <CustomIcon
                                className="mr5"
                                onClick={() => swiper?.slidePrev()}
                                imgName={'Button/UI_Button_Triangle-White_Left_01'}
                                width={15}
                                height={17}></CustomIcon>
                            <Swiper
                                slidesPerView={1}
                                centeredSlides={true}
                                autoplay = {{
                                    delay: 3000,
                                    // pauseOnMouseEnter: true,
                                    // disableOnInteraction: false,
                                }}
                                onSlideChange={(i) => {
                                }}
                                modules={[Autoplay]}
                                className="loginSwiper"
                                slideToClickedSlide={true}
                                onSwiper={(sw) => {
                                    setSwiper(sw)
                                }}
                                navigation={{ enabled: true }}
                            >
                                {partnerDataGrop.map((item, index) => {
                                    const {itemIndx} = item;
                                    return (
                                        <SwiperSlide key={index} className={s.loginSilde}>
                                            {itemIndx.map((i,ind) => {
                                                const { icon, width, height, url } = partnerData[i];
                                                    return(
                                                        <a key={i} href={url}>
                                                            <CustomIcon
                                                                
                                                                imgName={`Button/Logo/${icon}`}
                                                                width={width / 3}
                                                                height={height / 3} />
                                                        </a>
                                                    )
                                                })
                                            }
                                            </SwiperSlide>
                                        
                                        )
                                    })
                                }
        
                            </Swiper>
                            <CustomIcon
                                className="ml5"
                                onClick={() => swiper?.slideNext()}
                                imgName={'Button/UI_Button_Triangle-White_Right_01'}
                                width={15}
                                height={17}></CustomIcon>
                        </div>
                        }
                    </div>
                    <AntModal
                        width='330px'
                        title={iOSNotSafari ? t('WRONG BROWSER!') : t('Add to home screen page')}
                        className="confirmModalWrap"
                        centered={true}
                        open={showTips}
                        destroyOnClose={true}
                        onOk={() => setShowTips(false)}
                        onCancel={() => setShowTips(false)}
                    >
                        <HomeTips close={() => setShowTips(false)} setSkip={setSkip} iOSNotSafari={iOSNotSafari} tipsStr={'System Update Required'}/>
                    </AntModal>
        
                    <AntModal
                        width='330px'
                        title={`${window.isMomentsLink?'':t('Install Now to Start Using')}`}
                        className={`${window.isMomentsLink?'momentModalWrap':'confirmModalWrap'}`}
                        centered={true}
                        open={showInstallTips}
                        destroyOnClose={true}
                        onOk={closeAInstall}
                        onCancel={closeAInstall}
                    >
                        <PcPWAIntro 
                            closeAInstall={closeAInstall}
                            type={window.isMomentsLink?2:1} />
                    </AntModal>
                </div>
            }
        </>
    )
}
const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        hideNSFW: app.hideNSFW,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
        setWebIMUserInfo: (params) => {
            return dispatch({
                type: "app/setWebIMUserInfo",
                payload: params
            });
        },
        setShowBalance: (params) => {
            return dispatch({
                type: "app/setShowBalance",
                payload: params
            });
        },
        setInitTopUpAmount: (params) => {
            return dispatch({
                type: "app/setInitTopUpAmount",
                payload: params
            });
        },
        setCurrentMomentClubId: (params) => {
            return dispatch({
                type: "app/setCurrentMomentClubId",
                payload: params,
            });
        },
        setSwitchVideoListCursor: (params) => {
            return dispatch({
                type: "app/setSwitchVideoListCursor",
                payload: params,
            });
        },
        setSwitchVideoList: (params) => {
            return dispatch({
                type: "app/setSwitchVideoList",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Login));
