import WebIM from "./WebIM";
import store from "../store";
import { message, Modal } from "antd";
import { clgFn,getMessageFromId ,CHAT_TYPE,loginIntoIM,getEaseToken} from "./common";

const { dispatch, getState } = store;
let imErrCode = 0;
export default function initListener() {
  //登录登出
  WebIM.conn.listen({
    onOpened: function () {          //连接成功回调
      dispatch.app.setWebIMLogined(true);
      clgFn('WebIM listen','','onOpened')
    },
    onClosed: function () {
      clgFn('WebIM listen','red','onClosed')
      dispatch.app.setWebIMLogined(false)
      localStorage.removeItem("_TT_imLoginData");
      
      setTimeout(()=>{
        imErrCode===0 && loginIntoIM()
      },1000)
      
    },
    onError: (errCode) => {
      console.error(errCode);
      if(errCode.type === 40){
        imErrCode = 40
      }
      if (errCode.type === 206) {
        imErrCode = 206
        message.error({ content: "other devices logged" });
        WebIM.conn.close();
        window.location.href = window.location.href.split('#')[0];
      }
      if(errCode.type === 602){
        // message.error({ content: "other devices logged" });
      }
    },
    onTextMessage: function (message) {
      // const fromId = getMessageFromId(message);
      // console.log(message);
      
    },
    onOnline: ()=>{
      clgFn('WebIM listen','','onOnline')
      dispatch.app.setOnline(true)
    },
    onOffline: ()=>{
      clgFn('WebIM listen','red','onOffline')
      dispatch.app.setOnline(false)
    },
  })

  WebIM.conn.addEventHandler("messageEvent", {
    onTextMessage: (message) => {
      const fromId = getMessageFromId(message);
      dispatch.app.insertChatMessage({
        chatType: CHAT_TYPE.groupChat,
        fromId,
        messageInfo: {
          list: [message]
        }
      });
      // dispatch.app.setLiveMessage({
      //   messageInfo:[{...message}],
      //   reset:false,
      // })
    },
    onCustomMessage: (message) => {
      dispatch.app.insertChatCustomMessage({
        chatType: message.chatType,
        fromId: getMessageFromId(message),
        messageInfo: {
          list: [message]
        }
      });
    },
  })

  // 定时刷新当前appuserInfo信息
  setInterval(() => {
    // const uidList = Object.keys(getState().app.appUserInfo);
    // if (uidList && uidList.length) {
    //   getUsersInfo(uidList)
    // }
    const imLoginData = JSON.parse(localStorage.getItem("_TT_imLoginData")) || {};
    const now = new Date().getTime();
    if (imLoginData.expireTimes < now) {
      getEaseToken()
    }
    if(getState().app?.userInfo && imLoginData?.userId && imLoginData?.userId !== getState().app?.userInfo?.userId){
      WebIM.conn.close();
      localStorage.removeItem("_TT_imLoginData");
      loginIntoIM();
    }
  }, 5000)
}
